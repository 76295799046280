import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Link from "next/link";
import { commafy } from "utils/formatNumbers";
import fontSizes from "styles/fontSizes";
import media from "styles/media";
import { white00, black70 } from "styles/colors";
import Image from "next/image";

const Container = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 145px;
  ${media.medium`
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 290px;
    gap: 16px;
    width: 1384px;
  `}
  ${media.large`
    width: 100%;
  `}
`;

const GridItem = styled.div`
  grid-column-end: span ${(props) => props.$smallCols};
  grid-row-end: span ${(props) => props.$smallRows};
  ${media.medium`
    grid-column-end: span ${(props) => props.cols};
    grid-row-end: span ${(props) => props.rows};
  `}
  cursor: pointer;
`;

const TestContainer = styled.div`
  position: absolute;
`;

const RegionImage = styled(Image)`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const Heading = styled.div`
  ${fontSizes[4]};
  ${media.medium`
    ${fontSizes[6]};
  `}
  font-weight: bold;
  color: ${white00};
  text-align: center;
`;

const SubText = styled.div`
  ${fontSizes[1]};
  ${media.medium`
    ${fontSizes[4]};
  `}
  font-weight: bold;
  color: ${white00};
  text-align: center;
`;

const Overlay = styled.div`
  border-radius: 8px;
  background-color: ${black70};
`;

const RegionLink = styled(Link)`
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ImageGrid = ({ items }) => {
  return (
    <Container>
      {items.map((item) => (
        <GridItem
          cols={item.cols || 1}
          rows={item.rows || 1}
          $smallCols={item.smallCols || 1}
          $smallRows={item.smallRows || 1}
          key={item.href}
        >
          <RegionLink href={item.href}>
            <Overlay />
            <RegionImage src={item.src} fill alt="" />
            <TestContainer>
              {item.heading && (
                <Heading
                  dangerouslySetInnerHTML={{
                    __html: item.heading,
                  }}
                />
              )}

              {item.sailingCount && (
                <SubText>
                  {`${commafy(item.sailingCount)} cruises from $${commafy(
                    item.lowestPrice
                  )}`}
                </SubText>
              )}
            </TestContainer>
          </RegionLink>
        </GridItem>
      ))}
    </Container>
  );
};

ImageGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      srcFallback: PropTypes.string,
      href: PropTypes.string,
      heading: PropTypes.string,
      sailingCount: PropTypes.number,
      lowestPrice: PropTypes.number,
      rows: PropTypes.number,
      cols: PropTypes.number,
      smallRows: PropTypes.number,
      smallCols: PropTypes.number,
    })
  ),
};

export default ImageGrid;
