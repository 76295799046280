/*
 * add commas as thousands separators in numbers
 * via https://stackoverflow.com/a/2901298
 * only handles number types
 */
export const commafy = (num) => {
  if (typeof num === "number" && num > 999) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num;
};
